<template>
  <div class="add-shortcut-modal" v-if="canView">
    <b-modal
      id="bv-add-shortcut-modal"
      cancel-title="Annuler"
      :ok-disabled="!isValid"
      @ok="onAdd"
      @show="onShow"
      ok-variant="primary"
      ok-title="Ajouter"
      size="lg"
    >
      <template v-slot:modal-title>
        <b>
          <i :class="icon"></i>
          Ajouter un raccourci vers
          {{ name }}
        </b>
      </template>
      <div class="form-group">
        <b-row>
          <b-col>
            <b-form-group label-for="name" label="Titre">
              <b-form-input type="text" v-model="name"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <color-select @selected="getSelectedColor"></color-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-textarea v-model="description" id="description"></b-textarea>
            <div class="help-text">un texte additionnel</div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="block" label="Bloc">
              <b-form-select v-model="block">
                <b-form-select-option
                  v-for="elt of blockChoices"
                  :key="elt.id"
                  :value="elt"
                >
                  {{ elt.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label-for="order" label="Position">
              <b-form-select v-model="order">
                <b-form-select-option
                  v-for="elt of orders"
                  :key="elt.id"
                  :value="elt.id"
                >
                  {{ elt.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <div v-if="block && (block.id === 0)" class="new-block">
          <b-row>
            <b-col>
              <b-form-group label-for="blockName" label="Nom du bloc">
                <b-form-input type="text" v-model="blockName"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <color-select @selected="getBlockSelectedColor"></color-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-textarea v-model="blockDescription" id="block-description"></b-textarea>
              <div class="help-text">un texte additionnel</div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label-for="page" label="Page">
                <b-form-select v-model="blockPage">
                  <b-form-select-option
                    v-for="elt of pages"
                    :key="elt.id"
                    :value="elt.id"
                  >
                    {{ elt.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="column" label="Colonne">
                <b-form-select v-model="blockColumn">
                  <b-form-select-option
                    v-for="elt of columns"
                    :key="elt.id"
                    :value="elt.id"
                  >
                    {{ elt.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="order" label="Position">
                <b-form-select v-model="blockOrder">orders
                  <b-form-select-option
                    v-for="elt of blockOrders"
                    :key="elt.id"
                    :value="elt.id"
                  >
                    {{ elt.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ColorSelect from '@/components/Controls/ColorSelect.vue'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { makeShortcutBlock } from '@/types/shortcuts'
import { BackendApi } from '@/utils/http'
import { max } from '@/utils/math'

export default {
  name: 'add-shortcut-modal',
  components: { ColorSelect, },
  mixins: [BackendMixin],
  props: {
    blocks: Array,
  },
  data() {
    return {
      name: '',
      icon: '',
      path: '',
      query: '',
      backgroundColor: '',
      textColor: '',
      order: 0,
      block: null,
      blockName: '',
      blockPage: 0,
      blockColumn: 0,
      blockOrder: 0,
      blockBackgroundColor: '',
      blockTextColor: '',
      description: '',
      blockDescription: '',
    }
  },
  computed: {
    canView() {
      return this.hasPerm('home.add_shortcut')
    },
    isValid() {
      let isValid = !!this.name
      if (isValid && this.block && (this.block.id === 0)) {
        isValid = !!this.blockName
      }
      return isValid
    },
    navTitle() {
      return store.getters.navTitle
    },
    navIcon() {
      return store.getters.navIcon
    },
    blockChoices() {
      return this.blocks.concat([makeShortcutBlock({ name: 'Nouveau bloc', })])
    },
    pages() {
      let maxPage = 0
      if (this.blocks.length) {
        maxPage = max(this.blocks.map(elt => (elt.page + 1)))
      }
      maxPage += 1
      return Array.from(Array(maxPage).keys()).map(
        elt => { return { id: elt, name: '' + (elt + 1), } }
      )
    },
    columns() {
      return [0, 1, 2, 3, 4, 5].map(elt => { return { id: elt, name: '' + ('Colonne ' + (elt + 1)), } })
    },
    orders() {
      if (this.block && this.block.id) {
        const siblings = this.block.shortcuts.length
        return Array.from(Array(siblings + 1).keys()).map(
          elt => { return { id: elt, name: '' + (elt + 1), } }
        )
      } else {
        return [{ id: 1, name: '1', }]
      }
    },
    blockOrders() {
      const siblings = this.blocks.filter(
        elt => (elt.page === this.blockPage) && (elt.column === this.blockColumn)
      ).length
      return Array.from(Array(siblings + 1).keys()).map(
        elt => { return { id: elt, name: '' + (elt + 1), } }
      )
    },
  },
  mounted() {
  },
  watch: {
    orders: function() {
      if (this.orders.length) {
        this.order = this.orders[this.orders.length - 1].id
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    onShow() {
      this.path = window.location.pathname
      this.query = window.location.search
      this.name = this.navTitle
      this.icon = this.navIcon
      this.description = ''
      this.block = this.blockChoices[0]
    },
    async addBlock() {
      if (this.block.id === 0) {
        const url = '/api/home/shortcuts-blocks/'
        const data = {
          name: this.blockName,
          background_color: this.blockBackgroundColor,
          text_color: this.blockTextColor,
          page: this.blockPage,
          column: this.blockColumn,
          order: this.blockOrder,
          description: this.blockDescription,
        }
        const backendApi = new BackendApi('post', url)
        try {
          const resp = await backendApi.callApi(data)
          this.block = makeShortcutBlock(resp.data)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async addShortcut() {
      const url = '/api/home/shortcuts/'
      const data = {
        name: this.name,
        description: this.description,
        block: this.block.id,
        background_color: this.backgroundColor,
        text_color: this.textColor,
        icon: this.icon,
        path: this.path,
        order: this.order,
        query: this.query,
      }
      const backendApi = new BackendApi('post', url)
      try {
        await backendApi.callApi(data)
        await this.addSuccess('Le raccourci a été ajouté')
        this.$emit('done')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async onAdd() {
      if (this.isValid) {
        await this.addBlock()
        await this.addShortcut()
      }
    },
    getSelectedColor(event) {
      this.backgroundColor = event.color.background
      this.textColor = event.color.text
    },
    getBlockSelectedColor(event) {
      this.blockBackgroundColor = event.color.background
      this.blockTextColor = event.color.text
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.new-block {
  padding: 5px;
  background: #ccc;
}
</style>
