<template>
  <span v-if="hasPerm('payments.view_invoice')" class="invoice-badge">
    <span class="inner-badge" :class="getCssClass()" :style="getStyle()">
      <span v-if="invoice !== null">
        <span v-if="noLink">{{ label }}</span>
        <span v-else-if="onlyNumber">
          <span v-if="invoice.running">{{ label }}</span><span v-else>{{ invoice.getNumber() }}</span>
        </span>
        <router-link v-else :to="{ name: 'invoice-detail', params: { invoiceId: '' + invoice.id, }, }">
          <span v-if="!noAmount">{{ label }}<br v-if="withBr" /></span>
          <span v-else>{{ invoice.status }}<br v-if="withBr" /></span>
          {{ invoice.getNumber() }}
        </router-link>
      </span>
      <span v-else-if="toDo">
        <span v-if="noLink || entity === null || onlyNumber">À Facturer</span>
        <router-link v-else :to="getEntityLink(entity)">À Facturer</router-link>
      </span>
    </span>
    <span v-if="!noDownload && invoice && !invoice.running" class="invoice-badge-download no-print">
      <invoice-download :invoice="invoice" no-html no-btn></invoice-download>
    </span>
    <div v-if="canSend && (entity !== null) && !noLink && showSent">
      <invoice-send
        :invoice="invoice"
        :invoice-entity="entity"
        display-block
        @updated="updateAfterSend"
        vif="!entity.isOwn"
        small-link
      >
      </invoice-send>
    </div>
  </span>
</template>

<script>
import InvoiceSend from '@/components/Invoices/InvoiceSend'
import InvoiceDownload from '@/components/Invoices/InvoiceDownload'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'invoice-badge',
  components: {
    InvoiceDownload,
    InvoiceSend,
  },
  mixins: [BackendMixin],
  props: {
    entity: {
      type: Object,
      default: null,
    },
    invoice: Object,
    noLink: {
      type: Boolean,
      default: false,
    },
    onlyNumber: {
      type: Boolean,
      default: false,
    },
    allowSend: {
      type: Boolean,
      default: false,
    },
    allowSendNotPaid: {
      type: Boolean,
      default: false,
    },
    noAmount: {
      type: Boolean,
      default: false,
    },
    noDownload: {
      type: Boolean,
      default: false,
    },
    toDo: {
      type: Boolean,
      default: true,
    },
    basicLabel: {
      type: Boolean,
      default: false,
    },
    withBr: {
      type: Boolean,
      default: true,
    },
    fontSize: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    }
  },
  computed: {
    label() {
      return this.basicLabel ? 'Facture' : this.invoice.getLabel()
    },
    canSend() {
      return this.allowSend || this.allowSendNotPaid
    },
    showSent() {
      if (this.invoice) {
        if (this.invoice.isCancelled) {
          return false
        }
        if (this.invoice.running) {
          return false
        }
        if (this.invoice.isVerified) {
          return false
        }
        if (this.invoice.isPaid) {
          return false
        }
        if (this.invoice.paidPrice > this.invoice.totalPrice) {
          return false
        }
        if (this.invoice.isSent) {
          return this.allowSendNotPaid
        }
        if (this.invoice.totalPrice) {
          return true
        }
      }
      return false
    },
  },
  methods: {
    getEntityLink(entity) {
      let name = 'entities-detail'
      if (entity.family) {
        name = 'families-detail'
      }
      return {
        name: name,
        params: { entityId: '' + entity.id, },
        query: { tab: 'invoices', subTab: 'sales', },
      }
    },
    getStyle() {
      let style = {}
      if (this.fontSize) {
        style.fontSize = this.fontSize
      }
      return style
    },
    getCssClass() {
      let cssClass = ''
      if (!this.noDownload) {
        cssClass = 'with-download '
      }
      if (this.invoice) {
        cssClass += this.invoice.getStyle()
      } else {
        cssClass += 'todo-invoice'
      }
      return cssClass
    },
    updateAfterSend(invoice) {
      this.invoice.status = invoice.status
      this.invoice.isSent = true
      this.invoice = this.invoice.clone()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .invoice-badge {
    position: relative;
    display: block;
  }
  .inner-badge {
    color: #000 !important;
    display: block;
    margin-bottom: 2px;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    padding: 5px 2px;
    border-radius: 4px;
  }
  .inner-badge.with-download {
    min-height: 34px;
  }
  .inner-badge a {
    color: #000 !important;
  }
  .invoice-badge-download {
    position: absolute;
    right: 2px;
    top: 3px;
    font-size: 20px;
  }
  .invoice-badge-download a {
    color: #222 !important;
  }
</style>
