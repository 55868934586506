<template>
  <div class="shortcuts-screen" v-if="isAuthenticated">
    <a href @click.prevent="showAddShortcut()" v-if="false">
      <i
        class="fa fa-star add-shortcut-icon right-bar-icon"
        title="Créer un raccourci"
        v-b-tooltip.left
      ></i>
    </a>
    <a href @click.prevent="showScreen()">
      <i
        class="fa fa-star shortcuts-icon right-bar-icon"
        title="Voir vos raccourcis"
        v-b-tooltip.left
      ></i>
    </a>
    <div class="shortcuts-screen-content" v-show="isScreenShown" :style="windowsStyle">
      <b-row class="close-screen-line">
        <b-col cols="2">
          <b><i class="fa fa-star"></i> Raccourcis</b>
        </b-col>
        <b-col class="text-left" cols="4">
          <div v-if="pages.length > 1">
            <span
              class="shortcut-page"
              v-for="elt of pages"
              :key="elt.id"
              @click.prevent="selectPage(elt)"
              :class="{ selected: page === elt.id }"
            >
              {{ elt.name }}
            </span>
          </div>
        </b-col>
        <b-col cols="4" class="text-left links-bar">
          <a
            href
            @click.prevent="addShortcut"
            class="link2"
            v-b-tooltip.hover.left="'créer un raccourci vers ' + navTitle"
            v-if="!editMode"
          >
            <i class="fa fa-plus"></i>
          </a>
          <a class="link2" v-else style="color: transparent !important;">
            <i class="fa fa-plus"></i>
          </a>
          <a
            href
            @click.prevent="editShortcuts"
            class="link2"
            :class="{ 'link-on': editMode, }"
            v-b-tooltip.hover.right="'modifier vos raccourcis'"
          >
            <i class="fa fa-cogs"></i>
          </a>
          <div :class="{'edit-mode': editMode}" class="nav-title">{{ navTitle }}</div>
        </b-col>
        <b-col cols="2" class="text-right links-bar">
          <a class="close-link" href @click.prevent="hideScreen()"><i class="fa fa-close"></i></a>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="column of columns" :key="column">
          <div class="column">
            <div v-for="block of columnBlocks(column)" :key="block.id" class="shortcut-block" :style="block.getStyle()">
              <div class="shortcut-block-header">
                <span v-if="editMode" @click.prevent="editBlock(column, block)" :style="block.getStyle()">
                  <i class="fa fa-cog"></i>
                </span>
                {{ block.name }}
                <div class="shortcut-description" v-html="block.descriptionHtml()">
                </div>
              </div>
              <div class="shortcut-block-body">
                <div v-for="shortcut of block.shortcuts" :key="shortcut.id">
                  <div class="shortcut" :style="shortcut.getStyle()">
                    <div @click.prevent="clickShortcut(block, shortcut)">
                      <span v-if="editMode" :style="shortcut.getStyle(false)">
                        <i class="fa fa-cog"></i>
                      </span>
                      <span>
                        <i :class="shortcut.icon"></i>
                        {{ shortcut.name }}
                      </span>
                      <div class="shortcut-description" v-html="shortcut.descriptionHtml()">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <add-shortcut-modal
      :blocks="blocks"
      @done="loadBlocks()"
    ></add-shortcut-modal>
    <edit-shortcut-object-modal
      :blocks="blocks"
      :siblings="siblings"
      :kind="selectedKind"
      :obj="selectedObj"
      @done="loadBlocks()"
    ></edit-shortcut-object-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AddShortcutModal from '@/components/Shortcuts/AddShortcutModal.vue'
import EditShortcutObjectModal from '@/components/Shortcuts/EditShortcutObjectModal.vue'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import store from '@/store'
import { makeShortcutBlock } from '@/types/shortcuts'
import { BackendApi } from '@/utils/http'
import { max } from '@/utils/math'

export default {
  name: 'shortcuts-screen',
  components: {
    EditShortcutObjectModal,
    AddShortcutModal,
  },
  mixins: [BackendMixin],
  data() {
    return {
      isScreenShown: false,
      blocks: [],
      page: 0,
      editMode: false,
      selectedKind: '',
      selectedObj: null,
      siblings: [],
    }
  },
  created() {
  },
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    windowsStyle() {
      return { 'min-height': '100vw', }
    },
    navTitle() {
      return store.getters.navTitle
    },
    columns() {
      return [0, 1, 2, 3, 4, 5]
    },
    pages() {
      let maxPage = 0
      if (this.blocks.length) {
        maxPage = max(this.blocks.map(elt => elt.page + 1))
      }
      if (maxPage === 0) {
        maxPage = 1
      }
      return Array.from(Array(maxPage).keys()).map(
        elt => { return { id: elt, name: '' + (elt + 1), } }
      )
    },
  },
  watch: {
    pages: function() {
      if (this.pages.length) {
        this.page = this.pages[this.pages.length - 1].id
      } else {
        this.page = 0
      }
    },
  },
  methods: {
    ...mapActions(['addError']),
    // ...mapMutations(['setSearchedIndividuals']),
    showScreen() {
      this.isScreenShown = true
      this.editMode = false
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth', })
      this.loadBlocks()
    },
    hideScreen() {
      this.isScreenShown = false
    },
    columnBlocks(column) {
      return this.blocks.filter(
        elt => (elt.page === this.page) && (elt.column === column)
      )
    },
    async loadBlocks() {
      this.blocks = []
      const url = '/api/home/shortcuts-blocks/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.blocks = resp.data.map(makeShortcutBlock)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    addShortcut() {
      this.$bvModal.show('bv-add-shortcut-modal')
    },
    editShortcuts() {
      this.editMode = !this.editMode
    },
    showAddShortcut() {
      this.showScreen()
      this.addShortcut()
    },
    queryToArgs(query) {
      if (!query) {
        return {}
      }
      // Diviser les paramètres en paires clé-valeur
      const queryItems = query.split('?')[1].split('&')
      const queryData = {}
      // Parcourir chaque paire clé-valeur
      queryItems.forEach(
        param => {
          if (param.indexOf('=') < 0) {
            queryData[decodeURIComponent(param)] = ''
          } else {
            const [key, value] = param.split('=')
            // Décoder les valeurs pour gérer les caractères spéciaux
            const safeKey = decodeURIComponent(key)
            const safeValue = decodeURIComponent(value)
            if (queryData.hasOwnProperty(safeKey)) {
              const existingValue = queryData[safeKey]
              if (typeof existingValue === 'string') {
                queryData[safeKey] = [existingValue, safeValue]
              } else {
                queryData[safeKey] = existingValue.concat([safeValue])
              }
            } else {
              queryData[safeKey] = safeValue
            }
          }
        }
      )
      return queryData
    },
    showPath(shortcut) {
      const result = router.resolve(
        shortcut.path
      )
      const queryData = this.queryToArgs(shortcut.query)
      if (result.resolved) {
        const dest = result.resolved
        router.push(
          {
            name: dest.name,
            params: dest.params,
            query: queryData,
          }
        )
        this.hideScreen()
      } else {
        window.location.pathname = shortcut.path + '?' + shortcut.query
      }
    },
    showEditModal() {
      const that = this
      this.$nextTick(
        () => that.$bvModal.show('bv-edit-shortcut-modal')
      )
    },
    editBlock(column, obj) {
      this.selectedObj = obj
      this.selectedKind = 'block'
      this.siblings = this.columnBlocks(column)
      this.showEditModal()
    },
    editShortcut(block, obj) {
      this.selectedObj = obj
      this.selectedKind = 'shortcut'
      this.siblings = block.shortcuts
      this.showEditModal()
    },
    selectPage(page) {
      this.page = page.id
    },
    clickShortcut(block, shortcut) {
      if (this.editMode) {
        this.editShortcut(block, shortcut)
      } else {
        this.showPath(shortcut)
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.shortcuts-screen {
  .shortcuts-icon {
    bottom: 100px;
  }

  .add-shortcut-icon {
    bottom:  160px;
  }

  a.link, a.link2 {
    display: inline-block;
    padding: 0 5px;
    text-align: center;
    color: #fff !important;
    border-radius: 16px;
    min-width: 24px;
  }

  a.link2 {
    font-size: 24px;
  }

  a.link.link-on, a.link2.link-on {
    background: #fff;
    border-radius: 50%;
    color: #444 !important;
  }

  a.close-link {
    color: #fff !important;
  }

  .shortcuts-screen-content {
    width: 100vw;
    top: 0;
    left: 0;
    min-height: 100vmax;
    position: absolute;
    background: #444;
    color: #fff;
    opacity: 1;
    z-index: 50;
    padding: 30px 50px;
  }

  .close-screen-line {
    font-size: 32px;
    margin-bottom: 10px;
  }

  .column {
    padding: 10px;
    background: #404040;
    border-radius: 4px;
    min-height: 80vh;
  }

  .shortcut-block {
    font-size: 18px;
    display: block;
    border: solid 2px #505050;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  .shortcut-block-header {
    padding: 5px;
    font-weight: bold;
    border-bottom: solid 1px #505050;
  }
  .shortcut-block-body {
    padding: 5px;
  }

  .shortcut {
    font-size: 18px;
    display: block;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 16px 0 0 16px;
    text-decoration: none;
    cursor: pointer;
  }
  .links-bar a {
    margin-right: 20px;
    display: inline-block;
  }
  .shortcut-page {
    font-size: 16px;
    padding: 2px;
    text-align: center;
    border-radius: 50%;
    border: solid 1px #fff;
    display: inline-block;
    margin: 0 5px;
    height: 32px;
    min-width: 32px;
    cursor: pointer;
  }
  .shortcut-page.selected {
    background: #fff;
    color: #222;
  }
  .shortcut-description {
    font-size: 13px;
  }
  .nav-title {
    font-size: 12px;
  }
  .nav-title.edit-mode {
    color: transparent;
  }
  .nav-title:before {
    content: '';
    position: absolute;
    top: 42px;
    left: 25px;
    width: 0;
    height: 0;
    border-bottom: 10px solid #fff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
  .nav-title.edit-mode:before {
    border-bottom: 10px solid transparent;
  }
}
</style>
